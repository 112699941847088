import { CACHE_TASK_GROUP_REQUESTED, CACHE_TASK_GROUP_RECEIVED, CACHE_TASK_GROUP_ERROR } from './ActionTypes';
import { createRequestActions } from 'setup-guide-api/actions/ActionTypes';
import { fetchTasks } from 'setup-guide-api/actions/TaskActions';
import { getCacheTaskGroupData } from '../selectors/cacheData';
export function prefetchTasks(groupKey) {
  return (dispatch, getState) => {
    // Skip prefetching if the task group is already cached and not expired
    if (!groupKey || getCacheTaskGroupData(getState(), groupKey)) {
      return undefined;
    }
    return dispatch(fetchTasks(groupKey, {
      actions: createRequestActions(CACHE_TASK_GROUP_REQUESTED, CACHE_TASK_GROUP_RECEIVED, CACHE_TASK_GROUP_ERROR)
    }));
  };
}