import { getPrimaryTracker } from 'usage-tracker-container';
import events from '../events.yaml';
import devLogger from 'react-utils/devLogger';
const makeUsageTrackerContainer = () => {
  let usageTracker = null;
  const setLibraryTracker = tracker => {
    usageTracker = tracker;
  };
  const resetLibraryTracker = () => {
    usageTracker = null;
  };
  const loadLibraryTracker = () => {
    try {
      const appTracker = getPrimaryTracker('ContentUILib');
      setLibraryTracker(appTracker.clone({
        preserveTrackerProperties: false,
        trackerName: `ContentUILib`,
        events
      }));
    } catch (_unused) {
      devLogger.warn({
        message: "tracker is undefined in usage-tracker-container. Pass a value tracker via setPrimaryTracker(usageTracker, 'ContentUILib')",
        key: 'CONTENT_UI_LIB_TRACKER_NOT_SET'
      });
    }
  };
  const getLibraryTracker = () => {
    if (!usageTracker) {
      loadLibraryTracker();
    }
    return usageTracker;
  };
  return {
    setLibraryTracker,
    resetLibraryTracker,
    getLibraryTracker
  };
};
export const usageTrackerContainer = makeUsageTrackerContainer();
export const getLibraryTrack = () => {
  const tracker = usageTrackerContainer.getLibraryTracker();
  return tracker ? tracker.track : () => {};
};