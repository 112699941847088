import http from 'hub-http/clients/apiClient';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Exte... Remove this comment to see the full error message
import { anyExtensionInstalled } from 'ExtensionInstallHelpers/utils/anyExtensionInstalled';
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { HubsSettingClient } from 'frontend-preferences-client';
import { connectAdsAccount, connectDomain, connectInbox, connectCalendar, connectInboxAndCalendar, connectYourDomainTask, createABlogPost, createYourBlogTask, installExtension, addTrackingCode, explorePublishSite, selectStyle, buildSite, buildSiteV2, pageEditorDemo, postFirstBlog, selectActiveTheme, buildAiWebsite, createPodcast, remixContent, seoRecommendations } from 'getting-started-shared-tasks/manualDetectedTasks';
const CONNECTED_ACCOUNTS = 'connectedaccounts/v1/feature';
const BLOG_SETTINGS = 'blog-settings/v1/blogs';
const SITE_PAGES = 'cospages/v2/dashboard/site-pages';
const PUBLISHED_PAGE_STATE = 'PUBLISHED_OR_SCHEDULED';
const CMS_GROWTH_THEME_PATH = '@hubspot/growth';
const CMS_GROWTH_THEME_SETTINGS = `designmanager/v1/themes/by-path/${CMS_GROWTH_THEME_PATH}/settings`;
const BRANDING_PRIMARY_BRANDKIT = 'branding/v1/primary-brand-kit';
const ADS_ACCOUNT = 'ads/v1/ad-account/user-overview';
const BLOGS = 'blogs/v3/blog-posts/crm-search/search';
const PODCAST = 'podcasts/v1/shows';
const CONTENT_REMIX = 'content-remix/flows/v1';
const DOMAIN_OPTIMIZATIONS = 'optimizations/v2/domains';
const hubsSettingClient = HubsSettingClient.forCaller('setup-guide-api');
export const fetchConnectedAccounts = () => Promise.all([http.get(`${CONNECTED_ACCOUNTS}/FACSIMILE_INBOX`).then(resp => resp).catch(captureNetworkError), http.get(`${CONNECTED_ACCOUNTS}/CRM_EMAIL_INTEGRATION`).then(resp => resp).catch(captureNetworkError)]).then(([inboxesList = [], featureList = []]) => {
  const inboxes = inboxesList.filter(inbox => !inbox.inbox.shared).reduce((inboxMap, inbox) => {
    inboxMap[inbox.accountId] = inbox;
    return inboxMap;
  }, {});
  return featureList.filter(feature => !feature.shared).map(feature => {
    const {
      accountId,
      state
    } = feature;
    if (inboxes[accountId]) {
      feature.errorType = inboxes[feature.accountId].inbox.errorType;
    }
    let enabled;
    if (state === 'ENABLED') {
      enabled = true;
    } else if (['AVAILABLE', 'DISABLED'].includes(state)) {
      enabled = false;
    }
    return {
      enabled,
      emailAddress: accountId,
      featureType: feature.type,
      serviceType: feature.emailProviderType,
      errorType: feature.errorType
    };
  });
});
export function fetchConnectedDomain() {
  return http.get('cos-domains/v1/domains?isInternalDomain=false').then(response => {
    const {
      objects
    } = response;
    return objects && objects.length > 0;
  }).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function fetchIsDomainUsedForSitePage() {
  return http.get('cos-domains/v1/domains?isInternalDomain=false').then(response => {
    const {
      objects
    } = response;
    return objects && objects.length > 0 && objects.filter(({
      usageTypes,
      isConnectedCustomDomain
    }) => usageTypes && usageTypes.includes('USED_FOR_WEBSITE') && isConnectedCustomDomain).length > 0;
  }).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export const fetchExtensionInstallStatus = () => {
  return anyExtensionInstalled().then(installed => Boolean(installed)).catch(e => {
    captureNetworkError(e);
    return false;
  });
};
export function fetchTrackingCodeInstalled() {
  return hubsSettingClient.fetch('scriptLoaderInstallStatus', '').then(installStatus => {
    if (!installStatus) {
      return false;
    }
    return installStatus === 'INSTALLED';
  }).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
function isConnectedAccountEnabled() {
  return fetchConnectedAccounts().then(features => {
    return features.some(feature => feature.enabled);
  });
}
export function fetchBlogs() {
  return http.get(BLOG_SETTINGS).then(response => {
    const {
      objects
    } = response;
    return Boolean(objects && objects.length > 0);
  }).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function fetchSitePages(options = {}) {
  return http.get(SITE_PAGES, options);
}
export function fetchHasPublishedSitePages() {
  return fetchSitePages({
    query: {
      state: PUBLISHED_PAGE_STATE,
      limit: 1
    }
  }).then(({
    total
  }) => total > 0).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function fetchHasHitSitePagesLimit() {
  return fetchSitePages({
    query: {
      limit: 1
    }
  }).then(({
    total
  }) => total >= 10).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function fetchHasSitePages() {
  return fetchSitePages({
    query: {
      limit: 1
    }
  }).then(({
    total
  }) => total > 0).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function fetchHasGrowthThemeBeenEdited() {
  return http.get(CMS_GROWTH_THEME_SETTINGS).then(({
    themeSettings: {
      values
    }
  }) => values && Object.keys(values).length > 0).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function fetchActiveTheme() {
  return http.get(BRANDING_PRIMARY_BRANDKIT).then(({
    activeThemePath
  }) => activeThemePath).catch(e => {
    // 404 status is expected if there's no primary brandkit
    if (e.status !== 404) {
      captureNetworkError(e);
    }
    return undefined;
  });
}
export function fetchIsGrowthThemeActive() {
  return fetchActiveTheme().then(activeThemePath => activeThemePath === CMS_GROWTH_THEME_PATH);
}
export function fetchIsCustomThemeActive() {
  return fetchActiveTheme().then(activeThemePath => {
    return !!activeThemePath && activeThemePath !== CMS_GROWTH_THEME_PATH;
  });
}
export function isBuildSiteV2Complete() {
  return Promise.all([fetchIsCustomThemeActive(), fetchHasHitSitePagesLimit()]).then(([isCustomThemeActive, hasHitSitePagesLimit]) => isCustomThemeActive || hasHitSitePagesLimit);
}
export function isSelectStyleComplete() {
  return Promise.all([fetchHasGrowthThemeBeenEdited(), fetchIsGrowthThemeActive()]).then(([isGrowthThemeEdited, isGrowthThemeActive]) => isGrowthThemeEdited && isGrowthThemeActive);
}
export function hasActiveTheme() {
  return fetchActiveTheme().then(activeThemePath => Boolean(activeThemePath));
}
export function hasPublishedBlog() {
  const blogQueryFilters = [{
    property: 'hs_translated_from_id',
    operator: 'NOT_HAS_PROPERTY'
  }, {
    property: 'hs_state',
    operator: 'IN',
    values: ['PUBLISHED']
  }];
  return http.post(BLOGS, {
    data: {
      count: 1,
      objectTypeId: 'content',
      offset: 0,
      filterGroups: [{
        filters: blogQueryFilters
      }]
    }
  }).then(response => response && response.totalCount > 0).catch(error => {
    captureNetworkError(error);
    return false;
  });
}
export function isConnectedAdsAccount() {
  return http.get(ADS_ACCOUNT).then(({
    accounts = []
  }) => accounts.some(({
    status
  }) => status === 'ACTIVE')).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function hasPodcastShow() {
  return http.get(PODCAST).then(({
    total
  }) => total > 0).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export function hasOptimizationsDomain() {
  return http.get(DOMAIN_OPTIMIZATIONS).then(({
    total
  }) => total > 0).catch(error => {
    captureNetworkError(error);
    return false;
  });
}
export function hasRemixedContent() {
  return http.get(CONTENT_REMIX).then(flow => flow.some(({
    nodes
  }) => nodes.length > 1)).catch(e => {
    captureNetworkError(e);
    return false;
  });
}
export const MANUAL_DETECTED_TASKS_PROGRESS_API = {
  [addTrackingCode]: fetchTrackingCodeInstalled,
  [connectAdsAccount]: isConnectedAdsAccount,
  [connectDomain]: fetchConnectedDomain,
  [connectCalendar]: isConnectedAccountEnabled,
  [connectInbox]: isConnectedAccountEnabled,
  [connectInboxAndCalendar]: isConnectedAccountEnabled,
  [connectYourDomainTask]: fetchIsDomainUsedForSitePage,
  [createABlogPost]: hasPublishedBlog,
  [createYourBlogTask]: fetchBlogs,
  [installExtension]: fetchExtensionInstallStatus,
  [explorePublishSite]: fetchHasPublishedSitePages,
  [selectStyle]: isSelectStyleComplete,
  [buildSite]: fetchHasHitSitePagesLimit,
  [buildSiteV2]: isBuildSiteV2Complete,
  [pageEditorDemo]: fetchHasPublishedSitePages,
  [postFirstBlog]: hasPublishedBlog,
  [selectActiveTheme]: hasActiveTheme,
  [buildAiWebsite]: fetchHasSitePages,
  [createPodcast]: hasPodcastShow,
  [remixContent]: hasRemixedContent,
  [seoRecommendations]: hasOptimizationsDomain
};
export const checkManualDetectedTaskProgress = taskKey => {
  const progressApi = MANUAL_DETECTED_TASKS_PROGRESS_API[taskKey];
  if (!progressApi) {
    return Promise.resolve({
      taskKey,
      completed: false
    });
  }
  return progressApi().then(completed => ({
    taskKey,
    completed
  }));
};