'use es6';

import I18n from 'I18n';
import sortOptions from './sortOptions';
let allLanguageOptions = [];
const getLanguagesByCode = () => {
  // Should return lowercase language codes
  return I18n.text('SharedI18nStrings.languageNames');
};
export const getLanguageOptions = ({
  optionsFilter
}) => {
  if (!optionsFilter && allLanguageOptions.length) {
    return allLanguageOptions;
  } else if (optionsFilter && allLanguageOptions.length) {
    return allLanguageOptions.filter(optionsFilter);
  }
  const languagesByCode = getLanguagesByCode();
  allLanguageOptions = Object.keys(languagesByCode).map(key => {
    return {
      text: I18n.text(`SharedI18nStrings.languageNames.${key}`),
      value: key
    };
  }).sort(sortOptions);
  if (optionsFilter) {
    return allLanguageOptions.filter(optionsFilter);
  }
  return allLanguageOptions;
};
export const getOptionsForValidLanguages = ({
  validLanguages,
  optionsFilter
}) => {
  let options = [];
  const languagesByCode = getLanguagesByCode();
  validLanguages.forEach(lang => {
    if (languagesByCode[lang]) {
      options.push({
        text: I18n.text(`SharedI18nStrings.languageNames.${lang}`),
        value: lang
      });
    }
  });
  if (optionsFilter) {
    options = options.filter(optionsFilter);
  }
  return options.sort(sortOptions);
};