const HUBSPOT = 'HUBSPOT';
const USED = 'USED';
const MARKETPLACE = 'MARKETPLACE';
const ALL_TEMPLATES = 'ALL';
const FAVORITED = 'FAVORITED';
const RECENTLY_USED = 'RECENTLY_USED';
const HIDDEN = 'HIDDEN';
export const ThemeFilters = Object.freeze({
  USED,
  HUBSPOT,
  MARKETPLACE
});
export const TemplateFilters = Object.freeze({
  ALL_TEMPLATES,
  FAVORITED,
  RECENTLY_USED,
  HIDDEN
});