import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { switchView } from '../../actions/AppActions';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
import { CONSOLIDATED_STARTER_GROUP_KEYS, INVITED_USER_CONSOLIDATED_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import { usePortalId } from 'setup-guide-components/hooks/hub';
import { usePortalExperiment } from 'setup-guide-api/dataFetchingClient/queries/growthPostPurchase';
export const GPP0001_TREATMENT_KEY = 'gpp-0001';
export const GPP0001_PARAMETER_OPTIONS = {
  CONTROL: 'control',
  VARIANT: 'variation'
};
const GPP0001_SALES_GROUP_KEYS = {
  STARTER_VIEW_VARIANT: 'gpp0001_sales_starter_view_variant',
  STARTER_VIEW_CONTROL: 'gpp0001_sales_starter_view_control',
  INVITED_VIEW_VARIANT: 'gpp0001_sales_invited_view_variant',
  INVITED_VIEW_CONTROL: 'gpp0001_sales_invited_view_control'
};
const GPP0001_ELIGIBILITY_CRITERIA = {
  LANGUAGE: 'en',
  REQUIRED_SCOPE: 'super-admin',
  ELIGIBLE_SEATS: ['core', 'sales-pro'],
  ELIGIBLE_GROUP_KEYS: [CONSOLIDATED_STARTER_GROUP_KEYS.starterSalesView, INVITED_USER_CONSOLIDATED_GROUP_KEYS.invitedSalesView]
};
const getGpp0001SalesGroupKey = (isPortalVariant, currentSalesGroupKey) => {
  switch (currentSalesGroupKey) {
    case CONSOLIDATED_STARTER_GROUP_KEYS.starterSalesView:
      return isPortalVariant ? GPP0001_SALES_GROUP_KEYS.STARTER_VIEW_VARIANT : GPP0001_SALES_GROUP_KEYS.STARTER_VIEW_CONTROL;
    case INVITED_USER_CONSOLIDATED_GROUP_KEYS.invitedSalesView:
      return isPortalVariant ? GPP0001_SALES_GROUP_KEYS.INVITED_VIEW_VARIANT : GPP0001_SALES_GROUP_KEYS.INVITED_VIEW_CONTROL;
    default:
      return currentSalesGroupKey;
  }
};
const useIsEligibleForGpp0001 = portalExperiment => {
  const {
    seats,
    user
  } = useSelector(state => state.auth) || {};
  return useMemo(() => {
    var _user$scopes;
    if (!portalExperiment || !user || !seats) return false;
    const {
      LANGUAGE,
      REQUIRED_SCOPE,
      ELIGIBLE_SEATS
    } = GPP0001_ELIGIBILITY_CRITERIA;
    const hasEligibleLanguage = user.locale.startsWith(LANGUAGE);
    const hasEligibleSeat = seats.some(seat => ELIGIBLE_SEATS.includes(seat));
    const hasRequiredScope = (_user$scopes = user.scopes) === null || _user$scopes === void 0 ? void 0 : _user$scopes.includes(REQUIRED_SCOPE);
    return hasEligibleLanguage && hasEligibleSeat && hasRequiredScope;
  }, [portalExperiment, user, seats]);
};
export const useMaybeUpdateGpp0001SalesGroupKey = () => {
  const dispatch = useDispatch();
  const portalId = usePortalId();
  const {
    portalExperiment
  } = usePortalExperiment(portalId, GPP0001_TREATMENT_KEY);
  const isEligibleForGpp0001 = useIsEligibleForGpp0001(portalExperiment);
  return useCallback(onboardingSettings => {
    var _onboardingSettings$v;
    const salesView = onboardingSettings === null || onboardingSettings === void 0 || (_onboardingSettings$v = onboardingSettings.views) === null || _onboardingSettings$v === void 0 ? void 0 : _onboardingSettings$v[ONBOARDING_VIEWS.SALES];
    if (!salesView) return onboardingSettings;
    const currentSalesGroupKey = salesView.groupKey;
    const isEligibleGroupKey = GPP0001_ELIGIBILITY_CRITERIA.ELIGIBLE_GROUP_KEYS.some(groupKey => groupKey === currentSalesGroupKey);
    if (portalExperiment && isEligibleForGpp0001 && isEligibleGroupKey && currentSalesGroupKey) {
      const newGroupKey = getGpp0001SalesGroupKey(portalExperiment.variant === GPP0001_PARAMETER_OPTIONS.VARIANT, currentSalesGroupKey);
      if (onboardingSettings.selectedView === ONBOARDING_VIEWS.SALES) {
        dispatch(switchView(newGroupKey, ONBOARDING_VIEWS.SALES));
      }
      return Object.assign({}, onboardingSettings, {
        views: Object.assign({}, onboardingSettings.views, {
          [ONBOARDING_VIEWS.SALES]: Object.assign({}, salesView, {
            groupKey: newGroupKey
          })
        })
      });
    }
    return onboardingSettings;
  }, [dispatch, isEligibleForGpp0001, portalExperiment]);
};