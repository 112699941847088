import { createTracker } from 'usage-tracker';
import { setPrimaryTracker } from 'usage-tracker-container';
import { withSessionReplay } from 'usage-tracker-session-replay';
import events from '../../events.yaml';
import { MASK_SELECTORS_FROM_SESSSION_REPLAY } from '../constants/sessionReplay';
export const eventNames = Object.keys(events).reduce((result, name) => {
  result[name] = name;
  return result;
}, {});
const trackerWithoutSessionReplay = createTracker({
  events
});
const tracker = withSessionReplay(trackerWithoutSessionReplay, {
  // 0 - 1, 0 means no sampling, 1 means all events are sent
  // This value represents 1%
  sampleRate: 0.01,
  // This allows to show the component but any text is masked
  maskSelectors: MASK_SELECTORS_FROM_SESSSION_REPLAY
});
setPrimaryTracker(tracker, 'ContentUILib');
export const getTracker = () => tracker;