export const deriveDomainOptions = domains => domains.reduce((acc, domainEntry) => {
  const {
    domain,
    isSslOnly
  } = domainEntry;
  const protocol = isSslOnly ? `https://` : `http://`;
  acc.push({
    value: domain,
    text: `${protocol}${domain}`
  });
  return acc;
}, []);
export const getPrimaryDomainForBlog = domains => domains.find(({
  primaryBlogPost,
  primaryBlog
}) => !!(primaryBlogPost || primaryBlog));
export const getIsEnterprise = scopes => scopes.includes('multi-domain-hosting');