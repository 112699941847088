export const APP_STARTED = 'APP_STARTED';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const ONBOARDING_SETTINGS_ERROR = 'ONBOARDING_SETTINGS_ERROR';
export const ONBOARDING_SETTINGS_RECEIVED = 'ONBOARDING_SETTINGS_RECEIVED';
export const ONBOARDING_VIEW_ACTIVATED = 'ONBOARDING_VIEW_ACTIVATED';
export const EXPERIMENTS_REQUESTING = 'EXPERIMENTS_REQUESTING';
export const EXPERIMENTS_SUCCESSFUL = 'EXPERIMENTS_SUCCESSFUL';
export const EXPERIMENTS_ERROR = 'EXPERIMENTS_ERROR';
export const CACHE_TASK_GROUP_REQUESTED = 'CACHE_TASK_GROUP_REQUESTED';
export const CACHE_TASK_GROUP_RECEIVED = 'CACHE_TASK_GROUP_RECEIVED';
export const CACHE_TASK_GROUP_ERROR = 'CACHE_TASK_GROUP_ERROR';