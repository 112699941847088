import * as sessionReplay from '@amplitude/session-replay-browser';
import * as browserHelper from 'usage-tracker-core/client/browserHelper';
import reportWarning from 'usage-tracker-core/client/reportWarning';
import * as defaultTrackers from 'usage-tracker-core/common/defaultTrackers';
import { AMPLITUDE_API_KEYS, DEFAULT_PII_SELECTORS } from './constants';
import * as srUtils from './utils';

// Creates a Proxy for an actual `usage-tracker` instance that will inject Amplitude's Session Replay
// and do all necessary logic to ensure events are properly configured and tracked
const applyProxy = (tracker, srPromise) => {
  return new Proxy(tracker, {
    get: (target, property) => {
      if (property === 'track') {
        // NOTE: This Proxy is not compatible with `usage-tracker-cdn` or any tracker running
        // on standalone mode, due to different method signatures and configurations.
        return (eventKey, eventProperties) => {
          // Ensures that the Session Replay Engine has successfully bootstrapped
          // Before actually calling the .track() method, so that the events get tracked
          // with the proper session replay properties
          void srPromise.then(() => {
            // Retrieves the latest deviceId and sessionId from the default trackers
            const deviceId = defaultTrackers.getDeviceId();
            const sessionId = defaultTrackers.getSessionId();

            // An `usage-tracker` session might change during time (device id's could also change)
            // hence if the sessionId differs, we update the Session Replay instance with the new
            // session id's and device id's so that the events get properly attached
            // Note: Calling `setSessionId` will trigger a call to Amplitude's /config endpoint
            // for Session Replay -- not sure why this is done, but it's how the SDK works
            // Note: Only checking if Session ID has changed is enough, because on `usage-tracker-core`
            // if the `device_id` changes, the `session_id` will also change.
            if (sessionId !== sessionReplay.getSessionId()) {
              sessionReplay.setSessionId(sessionId, deviceId);
            }

            // Retrieves the Session Replay Properties that should be injected within an Event
            const srProperties = sessionReplay.getSessionReplayProperties();

            // Note that the Session Replay Properties might get "flagged" as unknown properties
            return target.track(eventKey, Object.assign({}, eventProperties, srProperties));
          })
          // If by any means Session Replay fails to initialize or had an exception whilst initialising
          // we fallback to send the Event anyways the regular way, without any Session Replay metadata
          // to prevent loss of events and ensure consistency on our usage pipeline
          .catch(error => {
            // We report the issue to Sentry if available that a Session Replay failed
            // due to unknown circumstances (Amplitude, issues or whatever else=)
            reportWarning(error, {
              fingerprint: ['usage-tracker-js', 'tracker:sessionReplay', `event:${eventKey}`]
            });
            return target.track(eventKey, eventProperties);
          });
        };
      }
      if (property === 'clone') {
        return cloneConfig => applyProxy(target.clone(cloneConfig), srPromise);
      }
      return target[property];
    }
  });
};
const withSessionReplay = (tracker, options = {}) => {
  // Default values for all Session Replay Options
  const {
    sampleRate = 0.01,
    optOut = false,
    blockSelectors = [],
    maskSelectors = [],
    unmaskSelectors = [],
    abortSignal
  } = options;

  // Enables Session Replay debug mode, if Usage Tracker debug mode is enabled
  // (using the same way to enable debug mode for usage-tracker-js)
  const debugEnabled = browserHelper.isDebugEnabled;

  // Retrieves Amplitude Session Information if exists otherwise generates new session information
  // and retrieves it (fluent getter)
  const deviceId = defaultTrackers.getDeviceId();
  const sessionId = defaultTrackers.getSessionId();

  // Verifies if the Application is running on a pre-set disallowed Environment;
  // which includes if we're running on Portal 53 which should be disabled by default
  // or if running on a Selenium environment (which is a testing environment)
  // or if running on Jasmine (which is a testing environment)
  const isDisallowedEnvironment = srUtils.getIsDisallowedEnvironment();

  // Verifies if the current running environment is sensitive and should be blocked
  // since on sensitive environments we should not run Session Replay at all
  const isSensitiveEnvironmentPromise = srUtils.getIsSensitiveEnvironment();

  // Combine the default and extra-provided selectors to block
  const elementsToMask = DEFAULT_PII_SELECTORS.MASK.concat(maskSelectors);
  const elementsToBlock = DEFAULT_PII_SELECTORS.BLOCK.concat(blockSelectors);
  const elementsToUnmask = DEFAULT_PII_SELECTORS.UNMASK.concat(unmaskSelectors);
  const sessionReplayPromise = new Promise(resolve => {
    // The `isSensitiveEnvironmentPromise` always only resolves and never throws
    // hence it is completely safe to simply `void` it and straight resolve it
    void isSensitiveEnvironmentPromise.then(isSensitiveEnvironment => {
      // Note that skipping Session Replay bootstrap (`init`) call does not affect
      // calling other `sessionReplay` methods, such as `getSessionReplayProperties`
      // They will simply do nothing if Session Replay is not bootstrapped.
      const shouldSkipSessionReplay = isSensitiveEnvironment || isDisallowedEnvironment;

      // We completely disable Session Replay on sensitive environments
      // or on disallowed environments, preventing even the bootstrap of Session Replay
      // Note: If debug mode is enabled, we still bootstrap Session Replay
      // as it is a manual request to run Session Replayfor debugging purposes
      if (shouldSkipSessionReplay && !debugEnabled) {
        return resolve();
      }

      // Defines all Session Replay Settings
      let sessionReplaySettings = {
        // We allow Apps to opt-out of Session Replay within their instances when wrapping
        // on `withSessionReplay` method. (Assuming if they want to opt-out) on specific things
        // such as, QA environment, or specific Portals, etc.
        optOut,
        // If no sample-rate is provided, we default to sampling 1% of the sessions
        // @see https://www.docs.developers.amplitude.com/session-replay/sdks/standalone/#sampling-rate
        sampleRate,
        // The HAmplitude Session ID, for all trackers this will be a 1:1 match
        // to what we ingest, process and send to Amplitude on our own Events
        sessionId,
        // The HAmplitude Session ID, this will be a 1:1 match on most of the trackers.
        // Any tracking client that uses UTK as an identifier will have a non-matching device_id
        // since we replace the Device ID to the UTK for UTK-based identified events
        // Purely Anonymous (deviceId) and In-App trackers (email and portalId-based) trackers
        // will have always an 1:1 match to what we ingest, process and send to Amplitude on our own Events
        deviceId,
        // Allows us to mask specific elements from being sent to Amplitude for PII reasons
        // We by default include a bunch of predefined selectors, but Apps should add extra selectors on their own discretion
        privacyConfig: {
          // All the elements to be blocked by default on the SDK-level
          blockSelector: elementsToBlock,
          // All the elements to be masked by default on the SDK-level
          maskSelector: elementsToMask,
          // All the elements to be unmasked by default on the SDK-level
          unmaskSelector: elementsToUnmask,
          // The Conservative Setting by default will mask all text and all form fields
          // including HTML text, user input, links, etc.
          defaultMaskLevel: 'conservative'
        }
      };

      // If the user requests debugMode, which can be useful for test environments, or debugging its behaviour locally
      // or simply forcing Session Replay to run, we enable debug mode on the Session Replay level
      // This can be useful for observing sessions and many other things.
      if (debugEnabled) {
        console.warn(
        // Let the developer/user know that debug mode is enabled, and that we're skipping all security checks
        // and that we're forcing the sample rate to be 100% and opt-out to be false.
        '[usage-tracker-session-replay] debug mode enabled. Skipping environment, optOut and sampleRate checks.');
        sessionReplaySettings = Object.assign({}, sessionReplaySettings, {
          // Enables Debug Mode on Session Replay-level
          debugMode: true,
          // Log Level 4 = Debug Logs
          logLevel: 4,
          // We force everyone to not be opted out when debug is enabled
          optOut: false,
          // We force the sample rate to be 100% when debug is
          sampleRate: 1
        });
      }

      // Creates the Session Replay instance. Note that this returns an object
      // with a Promise which is the actual SessionReplay instance
      const sessionReplayInitResult = sessionReplay.init(browserHelper.isProdDeployment ? AMPLITUDE_API_KEYS.HUBSPOT_PRODUCT : AMPLITUDE_API_KEYS.HUBSPOT_PRODUCT_QA, sessionReplaySettings);

      // Resolves with the final Session Replay Promise
      return resolve(sessionReplayInitResult.promise);
    });
  });

  // Allows custom AbortSignal to be passed for shutting down the Session Replay instance
  // and prevent any further events from being sent to Amplitude
  if (typeof (abortSignal === null || abortSignal === void 0 ? void 0 : abortSignal.addEventListener) === 'function') {
    abortSignal.addEventListener('abort', () => {
      // If the AbortSignal is aborted, we shutdown the Session Replay instance
      // to prevent any further events from being sent to Amplitude
      sessionReplay.shutdown();
    });
  }

  // Applies a Proxy to the current Usage Tracker instance
  return applyProxy(tracker, sessionReplayPromise);
};
export { withSessionReplay };