import PortalIdParser from 'PortalIdParser';
/* Experiment config template */
// export const GOB000_TREATMENT_KEY = 'gobc-000';
// export const GOB000_PARAMETER_KEY = 'group';
// export const GOB000_PARAMETER_OPTIONS = {
//   CONTROL: 'control',
//   VARIANT1: 'variant',
// };
/* GPP0002 */
export const GPP0002_TREATMENT_KEY = 'gpp-0002';
export const GPP0002_PARAMETER_KEY = 'group';
export const GPP0002_PARAMETER_OPTIONS = {
  CONTROL: 'control',
  VARIANT: 'variant'
};
export const TREATMENTS_SCHEMAS = {
  [GPP0002_TREATMENT_KEY]: {
    identifier: PortalIdParser.get(),
    parameters: {
      [GPP0002_PARAMETER_KEY]: [GPP0002_PARAMETER_OPTIONS.CONTROL, GPP0002_PARAMETER_OPTIONS.VARIANT]
    }
  }
};