import { selectStyleV2, createYourBlogTask } from 'getting-started-shared-tasks/taskNames';
import { BlogQuickCreateButton } from './BlogQuickCreateButton';
import { TASK_KEY as ExplorePublishSiteTaskKey, ExplorePublishSiteButton } from './ExplorePublishSiteButton';
import { SelectYourStyleV2Button } from './SelectYourStyleV2Button';

// Re-defining task types here to be the subset we use for our buttons.
// CmsManualDetectedTaskButtons are used in setup-guide-ui and
// onboarding-tours-guide-ui which have conflicting task shape. If you are
// looking to implement or extend this type, please first reference
// the mentioned codebases to determine if they fit your use case.
export const CmsManualDetectedTaskButtons = {
  [ExplorePublishSiteTaskKey]: ExplorePublishSiteButton,
  [selectStyleV2]: SelectYourStyleV2Button,
  [createYourBlogTask]: BlogQuickCreateButton
};
export const makeCmsManualDetectedTaskButtons = __treatments => {
  return CmsManualDetectedTaskButtons;
};