import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { getGrowthPostPurchasePortalExperiment } from '../../api/growthPostPurchaseApi';
export const PORTAL_EXPERIMENT_QUERY = registerQuery({
  fieldName: 'portalExperiment',
  args: ['portalId', 'experimentKey'],
  fetcher({
    portalId,
    experimentKey
  }) {
    return getGrowthPostPurchasePortalExperiment(portalId, experimentKey);
  }
});
export const usePortalExperiment = (portalId, experimentKey) => {
  const _useQuery = useQuery(PORTAL_EXPERIMENT_QUERY, {
      variables: {
        portalId,
        experimentKey
      }
    }),
    {
      data
    } = _useQuery,
    queryResult = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  const portalExperiment = data ? data.portalExperiment : null;
  return Object.assign({
    portalExperiment
  }, queryResult);
};