export const QUICK_FETCH_PROGRESS_KEY = 'OnboardingProgress';
export const QUICK_FETCH_TASKS_KEY = 'OnboardingTasks';
export const QUICK_FETCH_TREATMENT_KEY = 'TreatmentRequests';
export const QUICK_FETCH_WORDPRESS_STATUS_KEY = 'WordpressStatusRequest';
export const QUICK_FETCH_GROWTH_POST_PURCHASE_PORTAL_EXPERIMENT_KEY = 'GrowthPostPurchasePortalExperiment';
export const getOnboardingProgressEarlyRequestKey = groupKey => {
  if (!groupKey) {
    return QUICK_FETCH_PROGRESS_KEY;
  }
  return `${QUICK_FETCH_PROGRESS_KEY}:${groupKey}`;
};
export const getOnboardingTasksEarlyRequestKey = groupKey => {
  if (!groupKey) {
    return QUICK_FETCH_TASKS_KEY;
  }
  return `${QUICK_FETCH_TASKS_KEY}:${groupKey}`;
};