import { useEffect, useState } from 'react';
import Raven from 'raven-js';
import enviro from 'enviro';
import { SURVEY_IDS } from '../constants/coac0001SurveyConstants';
// @ts-expect-error no types exported
import { useFeedbackLoader } from 'feedback-loader/useFeedbackLoader';
import { getHasViewedUserGuide, getIsCoac0001Control, setHasViewedUserGuide } from '../utils/growthPlan';
const isQa = enviro.isQa();
const surveyId = isQa ? SURVEY_IDS.QA : SURVEY_IDS.PROD;
const FeedbackSurvey = () => {
  const [shouldSeeSurvey, setShouldSeeSurvey] = useState(false);
  const {
    loadSurvey,
    clientLoaded
  } = useFeedbackLoader();
  useEffect(() => {
    Promise.all([getIsCoac0001Control(), getHasViewedUserGuide()]).then(([isControl, hasViewedUserGuide]) => {
      if (isControl) {
        if (hasViewedUserGuide) {
          setShouldSeeSurvey(true);
        } else {
          setHasViewedUserGuide();
        }
      }
    }).catch(error => Raven.captureException(error));
  }, []);
  useEffect(() => {
    if (clientLoaded && shouldSeeSurvey) {
      loadSurvey(surveyId, 'CSAT');
    }
    // deliberately excluding `loadSurvey` dependency as it loops infinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientLoaded, shouldSeeSurvey]);
  return null;
};
export default FeedbackSurvey;