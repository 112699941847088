import PortalIdParser from 'PortalIdParser';
import { parse, stringify } from 'hub-http/helpers/params';
import { parseUrl, buildUrl } from 'hub-http/helpers/url';
import { getFullUrl } from 'hubspot-url-utils';
import { getAppLink } from 'setup-guide-components/utils/urls';
import { CrmExperienceOption } from 'signup-constants/signupData/CrmExperienceOption';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import { ONBOARDING_SALES_FREE } from 'getting-started-shared-tasks/onboardingKeys';
export const HUBSPOT_ERROR_STATUS_PAGE_URL = `https://status.hubspot.com`;
const getBaseUrl = () => '/user-guide';
export const getRootUrl = () => `${getBaseUrl()}/${PortalIdParser.get()}`;
export const getQueryString = queryParams => {
  if (!queryParams) {
    return window.location.search.slice(1);
  }
  return stringify(queryParams);
};
const resolveFirstValue = (paramKey, paramValue) => {
  const value = Array.isArray(paramValue) ? paramValue[0] : paramValue;
  if (['activeTab', 'activeView'].includes(paramKey) && !Object.values(ONBOARDING_VIEWS).includes(value)) {
    return undefined;
  }
  return value;
};

/**
 * Returns all the values of query parameters.
 * @returns Record<string, string | string[]>
 */
export const getAllQueryParams = () => parse(getQueryString());

/**
 * Returns the first value of query parameters.
 * @returns Record<string, string | string[]>
 */
export const getQueryParams = () => {
  const queryParams = parse(getQueryString());
  if (typeof Proxy === 'undefined') {
    return Object.keys(queryParams).reduce((singleValueParams, paramKey) => {
      const paramValue = resolveFirstValue(paramKey, queryParams[paramKey]);
      if (paramValue) {
        singleValueParams[paramKey] = paramValue;
      }
      return singleValueParams;
    }, {});
  }
  return new Proxy(queryParams, {
    get: (target, key) => resolveFirstValue(key, target[key])
  });
};

/**
 * Returns the first value associated with give query parameter.
 * @returns Record<string, string | string[]>
 */
export const getQueryParam = key => {
  const paramValue = parse(getQueryString())[key];
  return resolveFirstValue(key, paramValue);
};
export const getUnenrollmentDashboardLink = () => `${getAppLink('reports-dashboard')}?skipGettingStarted=true`;
export const getEnrollDefaultGroupLink = () => {
  const urlDescriptor = parseUrl(window.location.href);
  const queryParams = parse(urlDescriptor.query);
  urlDescriptor.query = stringify(Object.assign({}, queryParams, {
    groupKey: FREE_GROUP_KEYS.freeSalesView,
    onboardingKey: ONBOARDING_SALES_FREE,
    activeTab: ONBOARDING_VIEWS.SALES,
    enroll: true
  }));
  return buildUrl(urlDescriptor);
};
export const getLangOverrideFromSignup = () => {
  const viaParam = getQueryParam('via');
  const isViaSignup = viaParam && !Array.isArray(viaParam) && viaParam.includes('signup');
  if (isViaSignup) {
    const langParam = getQueryParam('lang');
    return Array.isArray(langParam) ? langParam[0] : langParam;
  }
  return undefined;
};
export const redirectTo = url => window.location.href = url;
export const getOverrideExperienceLevel = () => {
  const experienceLevel = getQueryParam('experienceLevel');
  if (experienceLevel === 'inexperienced') {
    return {
      crmExperience: CrmExperienceOption.None,
      hasCrmExperience: false
    };
  }
  return {};
};
export const getOnboardingAdminUiUrl = () => {
  return `${getFullUrl('tools', {
    domainOverride: 'hubteam',
    hubletOverride: 'na1'
  })}/onboarding-admin-ui`;
};
export const getOnboardingScopesUiUrl = (portalId = PortalIdParser.get()) => {
  return `${getFullUrl('tools', {
    domainOverride: 'hubteam',
    hubletOverride: 'na1'
  })}/onboarding-scopes?portalId=${portalId}`;
};
export const getUserManagementNewUserFlowLink = inviteYourTeamTaskId => {
  let destinationUrl = `/settings/${PortalIdParser.get()}/users/new?via=user-guide`;
  if (inviteYourTeamTaskId) {
    destinationUrl += `&onboarding_referrer_task=${inviteYourTeamTaskId}`;
  }
  return destinationUrl;
};
export const GROWTH_GUIDE_URL = `/growth-plan/${PortalIdParser.get()}`;
export const redirectToGrowthPlan = () => {
  const {
    search
  } = window.location;
  redirectTo(`${GROWTH_GUIDE_URL}${search}`);
};