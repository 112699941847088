import http from 'hub-http/clients/apiClient';
import { DEFAULT_USER_GROUP_URL, TASK_BY_TASKID_URL, TASKS_URL, TASKS_URL_V2 } from '../constants/apiUrls';
/**
 * V1
 */

export const getTasks = () => {
  return http.get(TASKS_URL);
};
export const getDefaultGroupForUser = () => {
  return http.get(DEFAULT_USER_GROUP_URL);
};
export const getTaskById = taskId => {
  return http.get(`${TASK_BY_TASKID_URL}/${taskId}`);
};
export const getTasksByGroupKey = groupKey => {
  return http.get(`${TASKS_URL}/${groupKey}`);
};
export const setDefaultGroupForUser = groupKey => {
  return http.post(DEFAULT_USER_GROUP_URL, {
    groupKey
  });
};
export const skipTask = taskKey => {
  return http.post(`${TASKS_URL}/${taskKey}/skip`);
};
export const undoSkipTask = taskKey => {
  return http.delete(`${TASKS_URL}/${taskKey}/skip`);
};

/**
 * V2
 */

export const getTasksByGroupKeys = groupKeys => {
  const groupKeysQuery = groupKeys.map(groupKey => `groupKeys=${groupKey}`).join('&');
  return http.get(`${TASKS_URL_V2}?${groupKeysQuery}`);
};