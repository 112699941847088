import { useContext } from 'react';
import AuthContext from '../contexts/AuthContext';
export const useAuth = () => {
  return useContext(AuthContext);
};
export const useIsUngatedFor = gateKey => {
  return useAuth().gates.includes(gateKey);
};
export const useGates = () => {
  const {
    gates
  } = useAuth();
  return gates || [];
};
export const useScopes = () => {
  const {
    user
  } = useAuth();
  return user.scopes || [];
};
export const useHasScope = scope => {
  return useScopes().includes(scope);
};
export const useHasHelpDeskAccess = () => {
  return useHasScope('help-desk-access');
};