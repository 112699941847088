const GS = 'getting_started';
export const GROUP_KEY_GROUP_KEYS = {
  crm: `${GS}_crm`,
  default: `${GS}_default`,
  experienced: `${GS}_experienced`,
  experiencedSalesLeader: `${GS}_experienced_sales_leader`,
  experiencedSalesIc: `${GS}_experienced_sales_ic`,
  marketing: `${GS}_marketing`,
  sales: `${GS}_sales`,
  salesPaid: `${GS}_sales_paid`,
  salesPaidDemo: `${GS}_sales_paid_demo`,
  services: `${GS}_services`,
  cms: `${GS}_cms`,
  cmsStarter: `${GS}_cms_starter_onboarding`,
  cmsV2: `${GS}_cms_onboarding_v2`,
  cmsV3: `${GS}_cms_onboarding_v3`
};
export const END_TO_END_SALES_GROUP_KEYS = {
  inexperiencedCrmIC: `${GS}_end_to_end_crm_ic_inexperienced`,
  experiencedCrmIc: `${GS}_end_to_end_crm_ic_experienced`,
  inexperiencedCrmLeader: `${GS}_end_to_end_crm_inexperienced_sales_leader`,
  experiencedCrmLeader: `${GS}_end_to_end_crm_experienced_sales_leader`,
  salesPaidDemo: `${GS}_paid_sales_demo`,
  // GOB234
  quickImportTaskList: `${GS}_end_to_end_crm_experienced_sales_leader_gob234_quick_import`,
  // GOB251
  setupFocusedTaskList: `${GS}_end_to_end_crm_experienced_GOB251`
};
export const FREE_GROUP_KEYS = {
  crmEssentialsGroup: 'crm_essentials_group',
  setUpTheBasics: 'crm_set_up_the_basics',
  freeCommerceView: 'commerce_free_view',
  freeMarketingView: 'marketing_free_view',
  marketingToolsGroup: 'marketing_tools_group',
  attractLeads: 'marketing_attract_leads',
  engageLeads: 'marketing_engage_leads',
  trackMarketingPerformance: 'marketing_track_performance',
  freeSalesView: 'sales_free_view',
  salesToolsGroup: 'sales_tools_group',
  trackDeals: 'sales_track_deals',
  connectWithLeads: 'sales_connect_with_leads',
  trackSalesPerformance: 'sales_track_performance',
  freeServiceView: 'service_free_view',
  serviceToolsGroup: 'service_tools_group',
  supportCustomers: 'service_support_customers',
  freeCmsView: 'cms_free_view',
  cmsToolsGroup: 'cms_tools_group',
  buildWebsites: 'cms_build_websites',
  publishContent: 'cms_publish_content',
  freeCmsViewCmsSignup: 'cms_free_view_cms_signup',
  cmsToolsGroupCmsSignup: 'cms_tools_group_cms_signup',
  buildWebsitesCmsSignup: 'cms_build_websites_cms_signup',
  publishContentCmsSignup: 'cms_publish_content_cms_signup',
  freeContentView: 'content_view',
  contentToolsGroup: 'content_tools_group',
  createContent: 'create_content',
  manageContent: 'manage_content',
  optimizeContent: 'optimize_content',
  // PLG groupkeys
  freeCmsViewV2: 'cms_free_view-v2',
  cmsToolsGroupV2: 'cms_tools_group-v2',
  freeCmsViewCmsSignupV2: 'cms_free_view_cms_signup-v2',
  cmsToolsGroupCmsSignupV2: 'cms_tools_group_cms_signup-v2'
};
export const END_TO_END_MARKETING_GROUP_KEYS = {
  inexperiencedMarketer: `${GS}_end_to_end_mkt_inexperienced`,
  experiencedMarketer: `${GS}_end_to_end_mkt_experienced`,
  inexperiencedMarketerWP: `${GS}_end_to_end_mkt_inexperienced_wp`,
  experiencedMarketerWP: `${GS}_end_to_end_mkt_experienced_wp`,
  marketerSep2020: `${GS}_end_to_end_marketing_sep_20`
};
export const END_TO_END_ADMIN_GROUP_KEYS = {
  crmAdmin: `${GS}_end_to_end_crm_admin`
};
export const END_TO_END_STARTER_GROUP_KEYS = {
  marketingStarter: `${GS}_end_to_end_marketing_starter`,
  marketingStarterWP: `${GS}_end_to_end_marketing_starter_wp`,
  salesStarter: `${GS}_end_to_end_sales_starter`,
  salesStarterPaymentVariant: `${GS}_end_to_end_sales_starter_payment_variant`,
  salesStarterPAY031Variant: `${GS}_end_to_end_sales_starter_pay031_variant`,
  serviceStarter: `${GS}_end_to_end_service_starter`,
  serviceStarterWP: `${GS}_end_to_end_service_starter_wp`
};
export const END_TO_END_GROUP_KEYS = Object.assign({}, END_TO_END_SALES_GROUP_KEYS, END_TO_END_MARKETING_GROUP_KEYS, END_TO_END_ADMIN_GROUP_KEYS, END_TO_END_STARTER_GROUP_KEYS);
export const INVITED_USER_GROUP_KEYS = {
  invitedMarketing: `${GS}_marketing_invited`,
  invitedService: `${GS}_service_invited`
};
export const INEXPERIENCED_SIMPLIFIED_GROUP_KEYS = {
  INEXPERIENCED_SIMPLIFIED_MARKETING: `inexperienced_simplified_marketing_task_list`,
  INEXPERIENCED_SIMPLIFIED_SERVICE: `inexperienced_simplified_service_task_list`,
  INEXPERIENCED_SIMPLIFIED_SALESPERSON: `inexperienced_simplified_salesperson_task_list`,
  INEXPERIENCED_SIMPLIFIED_SALES_LEADER: `inexperienced_simplified_sales_leader_task_list`
};
export const EXPERIMENT_GROUP_KEYS = {
  // GOB220
  SIMPLIFIED_TASK_LIST: `${GS}_simplified_task_list`,
  SIMPLIFIED_TASK_LIST_WITH_TRAINING: `${GS}_simplified_task_list_with_training`,
  // GOB232
  SIMPLIFIED_TASK_LIST_FOR_MARKETER: `${GS}_simplified_task_list_for_marketer`,
  // GOB262
  GOB262_VARIANT_1: `${GS}_gob262_variant_1`,
  GOB262_VARIANT_1_WP: `${GS}_gob262_variant_1_wp`,
  GOB262_VARIANT_2: `${GS}_gob262_variant_2`,
  GOB262_VARIANT_2_WP: `${GS}_gob262_variant_2_wp`,
  // CMS
  CMS_VARIANT_1: `${GS}_cms_variant_1`,
  CMS_VARIANT_2: `${GS}_cms_variant_2`,
  // GOB385 card key
  GOB385_CARD_GROUP_KEY: 'starting_with_crm_from_user_guide_tasklist'
};
export const SUITE_OVERVIEW_GROUP_KEYS = {
  SUITE_STARTER_OVERVIEW: 'crm_suite_starter_overview',
  SUITE_STARTER_OVERVIEW_BASICS: 'crm_suite_starter_overview_basics',
  SUITE_STARTER_OVERVIEW_MARKETING: 'crm_suite_starter_overview_marketing_hub',
  SUITE_STARTER_OVERVIEW_SALES: 'crm_suite_starter_overview_sales_hub',
  SUITE_STARTER_OVERVIEW_SERVICE: 'crm_suite_starter_overview_service_hub',
  SUITE_STARTER_OVERVIEW_CMS: 'crm_suite_starter_overview_cms_hub',
  SUITE_STARTER_OVERVIEW_PAY031_VARIANT: 'crm_suite_starter_overview_pay031',
  SUITE_STARTER_OVERVIEW_PAY031_VARIANT_TILE: 'crm_suite_starter_overview_pay031_variant_tile',
  SUITE_STARTER_OVERVIEW_SALES_HUB_PAY031_VARIANT: 'crm_suite_starter_overview_sales_hub_pay031_variant'
};
export const INVITED_USER_CONSOLIDATED_GROUP_KEYS = {
  invitedMarketingView: 'marketing_invited_view',
  invitedSalesView: 'sales_invited_view',
  invitedServiceView: 'service_invited_view',
  invitedCmsView: 'cms_invited_view',
  invitedContentView: 'content_invited_view'
};
export const CONSOLIDATED_STARTER_GROUP_KEYS = {
  starterMarketingView: 'marketing_starter_view',
  starterSalesView: 'sales_starter_view',
  starterServiceView: 'service_starter_view',
  starterCmsView: 'cms_starter_view',
  starterCmsViewV2: 'cms_starter_view-v2',
  starterContentView: 'content_starter_view',
  starterCommerceView: 'commerce_starter_view'
};

// We prepend these keys with `gsp` to avoid collisions
export const GROWTH_SIDE_PANEL_GROUP_KEYS = {
  gspSetUpTheBasics: 'growth_side_panel_crm_set_up_the_basics',
  // Growth Plan
  gspPostUnboxingUseCase: 'growth_side_panel_post_unboxing',
  // Sales
  gspSalesUseCases: 'growth_side_panel_sales_use_cases',
  gspSalesTrackDeals: 'growth_side_panel_sales_track_deals',
  gspConnectWithLeads: 'growth_side_panel_sales_connect_with_leads',
  // Marketing
  gspMarketingUseCases: 'growth_side_panel_marketing_use_cases',
  gspMarketingAttractLeads: 'growth_side_panel_marketing_attract_leads',
  gspMarketingEngageLeads: 'growth_side_panel_marketing_engage_leads',
  gspMarketingTrackPerformance: 'growth_side_panel_marketing_track_performance',
  // Service
  gspServiceUseCases: 'growth_side_panel_service_use_cases',
  gspServiceSupportCustomers: 'growth_side_panel_service_support_customers'
};
export const GROUP_KEYS = Object.assign({}, GROUP_KEY_GROUP_KEYS, END_TO_END_GROUP_KEYS, INVITED_USER_GROUP_KEYS, EXPERIMENT_GROUP_KEYS, INEXPERIENCED_SIMPLIFIED_GROUP_KEYS, SUITE_OVERVIEW_GROUP_KEYS, INVITED_USER_CONSOLIDATED_GROUP_KEYS, {
  endToEndCrm: `${GS}_end_to_end_crm`
}, CONSOLIDATED_STARTER_GROUP_KEYS, GROWTH_SIDE_PANEL_GROUP_KEYS);
export const DEFAULT_GROUP_KEY = GROUP_KEYS.crm;