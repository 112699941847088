import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import Categories from 'ContentUtils/constants/Categories';
import { isNullOrUndefined } from 'ContentUtils/helpers/ObjectHelpers';
import { DEFAULT_BUID } from '../constants/apiDefaults';

/**
 * GET branding/v1/colors
 */
export function fetchBrandColors(businessUnitId, ...rest) {
  const endpoint = businessUnitId ? `branding/v1/colors/brand-kit?businessUnitId=${businessUnitId}` : 'branding/v1/colors/list-ensure-primary';
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(endpoint);
}
export function fetchBranding(...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('branding/v1/data');
}
export function fetchBusinessUnit(contentType, contentId, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  const typeApiSlug = Categories[contentType] ? Categories[contentType].api_slug : contentType;
  return httpClient.get(`cospages/v2/${typeApiSlug}/${contentId}?property=businessUnitId`);
}
export function fetchBrandKit(businessUnitId, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`branding/v1/brand-kit/business-unit/${isNullOrUndefined(businessUnitId) ? DEFAULT_BUID : businessUnitId}`);
}
export function fetchLogo(domain, businessUnitId, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  const query = Object.assign({}, domain && {
    domain
  }, businessUnitId && {
    businessUnitId
  });
  return httpClient.get('branding/v1/logos/logo', {
    query
  });
}
export const fetchPrimaryBrandKit = (createIfNotFound = false, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('branding/v1/primary-brand-kit').catch(e => {
    if (e.status !== 404 || !createIfNotFound) {
      throw e;
    }
    return httpClient.post('branding/v1/brand-kit', {
      data: {
        name: 'My brand kit'
      }
    });
  });
};