const ALL = 'ALL';
const CUSTOM = 'CUSTOM';
const HUBSPOT = 'HUBSPOT';
const PURCHASED = 'PURCHASED';
const STARTER = 'STARTER';
export const TemplateSourceFilters = Object.freeze({
  ALL,
  CUSTOM,
  HUBSPOT,
  PURCHASED,
  STARTER
});