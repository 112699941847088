import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
/**
 * GET designmanager/v1/templates/paginated-usable-templates
 * @param {Array} templateTypeIds
 */
export function fetchTemplateCounts(templateTypeIds, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('/designmanager/v1/templates/paginated-usable-templates', {
    query: {
      limit: 0,
      offset: 0,
      excludeUnusedInMemory: true,
      templateTypeIds
    }
  }).then(({
    allCount,
    favoriteCount,
    hiddenCount,
    recentlyUsedCount
  }) => ({
    allCount,
    favoriteCount,
    hiddenCount,
    recentlyUsedCount
  }));
}

/**
 * GET designmanager/v1/templates/paginated-usable-templates
 * @param {object} query { limit, offset, sorting, sourceFilter, name, type, folderName, themePath, templateTypeIds, excludeUnusedInMemory, hasDndArea }
 */
export function fetchTemplates(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('/designmanager/v1/templates/paginated-usable-templates', {
    query
  });
}