import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useViews } from '../../hooks/config';
import { prefetchTasks } from '../../actions/CacheDataActions';
import { requestIdleCallback, cancelIdleCallback } from '../../utils/polyfills';
const PrefetchSetupGuideViews = () => {
  const {
    views,
    selectedView
  } = useViews() || {};
  const dispatch = useDispatch();
  useEffect(() => {
    if (!views) {
      return undefined;
    }
    const groupKeys = Object.keys(views || {}).filter(view => view !== selectedView && views[view].groupKey).map(view => views[view].groupKey);
    if (groupKeys.length === 0) {
      return undefined;
    }
    let idleCallbackHandle;
    const timerHandle = setTimeout(() => {
      idleCallbackHandle = requestIdleCallback(() => {
        groupKeys.forEach(groupKey => void dispatch(prefetchTasks(groupKey || undefined)));
      });
    }, 10000);
    return () => {
      cancelIdleCallback(idleCallbackHandle);
      window.clearTimeout(timerHandle);
    };
  }, [dispatch, views, selectedView]);
  return null;
};
export default PrefetchSetupGuideViews;